/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Field, reduxForm, reset } from "redux-form";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import EmailSendIcon from "mdi-react/EmailSendIcon";
import LoadingIcon from "mdi-react/LoadingIcon";
import UndoIcon from "mdi-react/UndoIcon";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import ApiServices from "@/utils/ApiServices";
import Select from "react-select";
import { SELECT_STYLES, HOME_API, API } from "@/utils/constant";
import { errorMsgCustom, successMsgCustom } from '@/utils/message_herlper';
import renderDateTimePickerField from '@/shared/components/form/DateTimePicker';
import TimetableIcon from 'mdi-react/TimetableIcon';

import "./contact-style.css";

const SendEmail = (props) => {
    localStorage.setItem("account", "false");
    const [type, setType] = useState('live');

    const [countyList, setCountyList] = useState([]);
    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("custom");
    const [selectedOptionsPT, setSelectedOptionsPT] = useState([]);
    const [selectedOptionsPC, setSelectedOptionsPC] = useState([]);
    const [marketCut, setMarketCut] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCounty();
        getPropertyTypes();
    }, []);

    const getCounty = () => {
        ApiServices.county()
            .then((result) => {
                setCountyList(result.data);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    };

    const getTemplate = () => {
        ApiServices.getTemplate()
            .then((result) => {
                console.log('template result --> ', result);
                setMarketCut(result.data.email_content);

                setContent(result.data.email_content);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    }

    const getPropertyTypes = () => {
        ApiServices.PropertyTypes()
            .then((result) => {
                setPropertyTypeList(result.data);
            })
            .catch(function (error) {
                console.log(error);
                alert(error.message);
            });
    };

    // Dynamic data soon
    // const countyList = ["Harris", "Fort bend", "Brazoria", "Galveston", "Montgomery", "Travis", "Waller", "Austin", "Hays", "Williamson", "Guadalupe", "Fayette", "Calhoun", "Bexar", "Rockwall", "Chambers", "Polk", "Walker", "Liberty", "Matagorda", "Grimes", "Erath", "Wharton", "Tarrant", "Brazos", "Comal", "Ector", "Collin", "Victoria county", "Hidalgo", "Wise", "Burleson", "Bell", "Dallas", "San jacinto", "Aransas", "Nueces", "Jackson", "Washington"];

    // const PropertyTypeList = ['Residential', 'Apartment/Duplex', 'Vacant Lot', 'Commercial Vacant Lots', 'Agricultural Lands', 'Commercial', 'BPP - RPT', 'BPP - Client'];

    const PropertyCities = [
        "NORTH RICHLAND HILLS",
        "CONROE",
        "DRIPPING SPRINGS",
        "Katy",
        "Missouri City",
        "MONTGOMERY",
        "PEARLAND",
        "Richmond",
        "Rosenberg",
        "Stafford",
        "Sugar Land",
        "Weston Lakes",
        "13922 KINGSRIDE LN",
        "77018",
        "ALAMO",
        "ALGOA",
        "ALVIN",
        "ANGELTON",
        "ANGLETON",
        "Austin",
        "BACLIFF",
        "BAYCLIFF",
        "BAYOU VISTA",
        "BAYTOWN",
        "BELLAIRE",
        "BELLVILLE",
        "Benbrook",
        "BLUEWATER",
        "BOLING",
        "BRAZORIA",
        "BRENHAM",
        "BROOKSHIRE",
        "BROOKSIDE",
        "CANYON LAKE",
        "CAT SPRING",
        "CHANNELVIE",
        "CHANNELVIEW",
        "CLEAR LAKE SHORES",
        "CLEVALAND",
        "CLEVELAND",
        "CLUTE",
        "COLDSPRING",
        "COLLEGE STATION",
        "CONROE",
        "CORPUS CHRISTI",
        "CROSBY",
        "CROSBY TX",
        "CRYSTAL BE",
        "CRYSTAL BEACH",
        "CYPRESS",
        "DALLAS",
        "DAMON",
        "DANBURY",
        "DAYTON",
        "DEER PARK",
        "DEVERS",
        "DICKINSON",
        "DRIPPING S",
        "DRIPPING SPRINGS",
        "EAST BERNARD",
        "FISCHER",
        "FREEPORT",
        "FRESNO",
        "FRIENDSWOD",
        "FRIENDSWOO",
        "FRIENDSWOOD",
        "FRIENDWOOD",
        "FULSHEAR",
        "GALENA PARK",
        "GALENA PK",
        "GALENAPARK",
        "GALVESTON",
        "GANADO",
        "GILCHRIST",
        "GOODRICH",
        "GREENVILL",
        "HARRIS",
        "HEMPSTEAD",
        "HIGHLANDS",
        "HITCCHCOCK",
        "HITCHCOCK",
        "HOCKLEY",
        "HOSTON",
        "HOUSOTN",
        "HOUSTN",
        "HOUSTON",
        "HOUSTON T",
        "HOUSTON,TX",
        "HUFFMAN",
        "HUMBLE",
        "HUNTESVILLE",
        "HUNTSVILLE",
        "IOWA COLONY",
        "JAMAICA BEACH",
        "JERSEY VILLAGE",
        "JONES CREEK",
        "KATY",
        "KEMAH",
        "KINGWOOD",
        "KYLE",
        "LA MARQUE",
        "LA PORTE",
        "LAKE JACKS",
        "LAKE JACKSON",
        "LAKEWAY",
        "LAPORTE",
        "LEAGUE CITY",
        "LEAGUE CIT",
        "LEAGUE CITY",
        "LEANDER",
        "LIBERTY",
        "LIVERPOOL",
        "LIVINGSTON",
        "M0NTGOMERY",
        "MAGNOLIA",
        "MANVEL",
        "MARBLE FALLS",
        "MATAGORDA",
        "Meadows Place",
        "Mesquite",
        "MISSOURI",
        "MISSOURI C",
        "MISSOURI CITY",
        "MONTGOMERY",
        "MURPHY",
        "NAVASOTA",
        "NEEDVILLE",
        "NEEDVILLE,",
        "NEW BRAUNFELS",
        "NEW CANEY",
        "New Ulm",
        "NEW YORK",
        "NONE",
        "ORCHARD",
        "OUTSIDE CITY LIMITS",
        "PANORAMA VILLAGE",
        "PASADENA",
        "PEARLAN",
        "PEARLAND",
        "PINEHURST",
        "PLANTERSVI",
        "PLANTERSVILLE",
        "POINT BLANK",
        "PORT BOLIVAR",
        "PORT O CONNOR",
        "PORTER",
        "PRAIRIE VIEW",
        "RAYWOOD",
        "RICHARDS",
        "RICHMOND",
        "RICHWOOD",
        "ROSENBERG",
        "ROSHARON",
        "SAN ANTONIO",
        "SAN LEON",
        "SANTA FE",
        "SARGENT",
        "SEABROOK",
        "SEALY",
        "SHENANDOAH",
        "SHEPHERD",
        "SHOREACRES",
        "SOMERVILLE",
        "SOUTH HOUS",
        "SOUTH HOUSTON",
        "SPICEWOOD",
        "SPLENDORA",
        "SPRING",
        "STAFFORD",
        "SUGAR LAND",
        "SUGAR LAND,",
        "SUGAR LAND, TX",
        "SUGARLAND",
        "SURFSIDE",
        "SURFSIDE BEACH",
        "SWEENEY",
        "SWEENY",
        "TAYLOR LAKE VILLAGE",
        "TEXAS CITY",
        "THE WOODLA",
        "THE WOODLANDS",
        "TIKI",
        "TIKI ISLAN",
        "TIKI ISLAND",
        "TOMBALL",
        "TX",
        "UNINCORP",
        "VAN VLECK",
        "VICTORIA",
        "WALLER",
        "WEBSTER",
        "WEST COLUM",
        "WHARTON",
        "WIILLIS",
        "WILLIS",
        "WIMBERLEY",
        "WOODLANDS",
    ];

    const PropertyStates = ["CA", "NY", "SC", "TA", "TE", "TX"];

    const emailTemplates = [
        {
            value: "custom",
            label: "Custom",
        },
        // {
        //     value: "arbitration",
        //     label: "Arbitration",
        // },
        {
            value: "market-cut",
            label: "Market Cut",
        },
        {
            value: "no-change",
            label: "No Change",
        },
        // {
        //     value: "litigation-contract",
        //     label: "Litigation Contract",
        // },
    ];

    let PTList = [];
    PTList.push({ value: "all", label: "Select All" });
    PTList.push({ value: "none", label: "Select None" });
    for (let index = 0; index < propertyTypeList.length; index++) {
        PTList.push({
            value: propertyTypeList[index].id,
            label: propertyTypeList[index].type,
        });
    }

    let CountyList = [];
    CountyList.push({ value: "all", label: "Select All" });
    CountyList.push({ value: "none", label: "Select None" });
    for (let index = 0; index < countyList.length; index++) {
        CountyList.push({
            value: countyList[index].id,
            label: countyList[index].name,
        });
    }

    const setPTSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsPT) &&
            selectedOptionsPT.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = PTList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsPT(newDAta);
    };

    const setPCSeledtedAction = (value) => {
        const selectedValue =
            Array.isArray(selectedOptionsPC) &&
            selectedOptionsPC.length != 0 &&
            value
                ? value[value.length - 1]
                : value
                ? value[0]
                : [];
        let newDAta = [];
        if (selectedValue?.value == "none") {
            newDAta = [];
        } else if (selectedValue?.value == "all") {
            newDAta = CountyList.slice(2);
        } else {
            newDAta = value;
        }
        setSelectedOptionsPC(newDAta);
    };

    const handleSubmitAction = (e) => {
        e.preventDefault();
        setLoading(true);
       
        const form = e.target;
        const formData = new FormData(form);
        // Convert FormData to an object
        const formValues = Object.fromEntries(formData.entries());

        let PRResult =
            Array.isArray(selectedOptionsPT) &&
            selectedOptionsPT.map(({ value }) => value);
        let PCResult =
            Array.isArray(selectedOptionsPC) &&
            selectedOptionsPC.map(({ value }) => value);
        formValues.property_type = PRResult;
        formValues.property_county = PCResult;
        formValues.type = type;

        ApiServices.sendBatchEmail(formValues)
            .then((response) => {
                successMsgCustom(response?.data?.statusMsg);
                setSelectedOptionsPT([])
                reset();
            })
            .catch((err) => {
                errorMsgCustom(
                    err.response?.data?.message ||
                        err.message ||
                        "Something went wrong"
                );
            })
            .finally(() => {
                setLoading(false);
            });
        // handleSubmit(formValues);
    };








    const editor = useRef(null);
    const editorRef = useRef(null);
    const joditInstanceRef = useRef(null);
	const [content, setContent] = useState('');

	const config = useMemo(() => ({
        readonly: false,
        placeholder: 'Start typing...',
        uploader: {
            insertImageAsBase64URI: false,
            url: `${API}upload`, // Your Laravel API URL
            format: "json",
            filesVariableName: () => {
                return "file"
            },
            method: "POST",
            isSuccess: (resp) => resp.success,
            process: (resp) => {
                if (resp.success && resp.data.files.length) {
                    const fullFilePath = resp.data.baseurl + resp.data.files[0];
                    const fileName = resp.data.files[0];
                    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

                    const fileExtension = fileName.split('.').pop().toLowerCase();
    
                    if (joditInstanceRef.current?.selection) {
                        if (imageExtensions.includes(fileExtension)) {
                            // Insert as an image
                            joditInstanceRef.current.selection.insertImage(fullFilePath, null, 400);
                        } else {
                            // Insert a visually styled attachment link
                            const fileIcon = fileExtension === "pdf" 
                            ? "📄"  // PDF icon
                            : "📎"; // Generic file attachment icon
                            
                            joditInstanceRef.current.selection.insertHTML(`
                                <div style="
                                    display: inline-flex;
                                    align-items: center;
                                    padding: 8px 12px;
                                    background-color: #f1f1f1;
                                    border-radius: 5px;
                                    margin: 5px 0;
                                    font-family: Arial, sans-serif;
                                ">
                                    ${fileIcon}
                                    <a href="${fullFilePath}" target="_blank" 
                                        style="text-decoration: none; margin-left: 8px; font-weight: bold; color: #0073e6;">
                                        ${fileName}
                                    </a>
                                </div>
                            `);
                        }
                    } else {
                        console.error("⚠️ Jodit instance is still not ready.");
                    }
    
                    return fullFilePath;
                }
                return null;
            },
        },
        events: { 
            afterInit: (instance) => { 
                if (!joditInstanceRef.current) {  // Ensures it only sets once
                    console.log("✅ Jodit instance initialized:", instance);
                    joditInstanceRef.current = instance;
                }
            } 
        },
        allowDragAndDropFileToEditor: true,
    }), []);


    // useEffect(() => {
    //     if (joditInstanceRef.current) {
    //         joditInstanceRef.current.value = content; // Updates Jodit content safely
    //     }
    // }, [selectedTemplate]); // Only update when content changes

    return (
        <Container>
            <TitleBreadCrumbs title="Batch Email">
                <div className="page-breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="current_breadcrumbs">Batch Email</span>
                </div>
            </TitleBreadCrumbs>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <form
                                        className="send-email-form"
                                        onSubmit={handleSubmitAction}
                                    >
                                        <h3>Filter for Sending Email</h3>
                                        <hr />
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Property Type:
                                            </span>
                                            <div>
                                                <Select
                                                    options={PTList}
                                                    value={selectedOptionsPT}
                                                    isMulti="true"
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) =>
                                                        setPTSeledtedAction(
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="form__form-group">
                                            <span className="form__form-group-label">Property Cities:</span>
                                            <div>
                                                <Field
                                                    name="cities"
                                                    component="select"
                                                    defaultValue="none"
                                                    className="select-light-border"
                                                >
                                                    <option value="none">Nothing selected</option>
                                                    {PropertyCities.map((city) => (
                                                        <option key={city} value={city}>{city}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Property States:</span>
                                            <div>
                                                <Field
                                                    name="states"
                                                    component="select"
                                                    defaultValue="none"
                                                    className="select-light-border"
                                                >
                                                    <option value="none">Nothing selected</option>
                                                    {PropertyStates.map((state) => (
                                                        <option key={state} value={state}>{state}</option>
                                                    ))}
                                                </Field>
                                            </div>
                                        </div> */}
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Property County:
                                            </span>
                                            <div>
                                                {/*
                                                <Field
                                                    name="county"
                                                    component="select"
                                                    defaultValue=""
                                                    className="select-light-border"
                                                >
                                                    <option value="">
                                                        Nothing selected
                                                    </option>
                                                    {countyList.map(
                                                        (county) => (
                                                            <option
                                                                key={county.id}
                                                                value={
                                                                    county.id
                                                                }
                                                            >
                                                                {county.name}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                */}
                                                <Select
                                                    options={CountyList}
                                                    value={selectedOptionsPC}
                                                    isMulti="true"
                                                    styles={SELECT_STYLES}
                                                    onChange={(value) =>
                                                        setPCSeledtedAction(
                                                            value
                                                        )
                                                    }
                                                />                                                
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{display: 'none'}}>
                                            <span className="form__form-group-label">
                                                ZipCode:
                                            </span>
                                            <div className="form__form-group-field">
                                                {/* <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div> */}
                                                <Field
                                                    name="zipcode"
                                                    component="input"
                                                    defaultValue="text"
                                                    placeholder="ZipCode"
                                                />
                                            </div>
                                        </div>

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Tax Savings:
                                            </span>
                                            <div
                                                className="form__form-group-field"
                                                style={{ gap: "1rem" }}
                                            >
                                                {/* <div className="form__form-group-icon">
                                                    <MapMarkerIcon />
                                                </div> */}

                                                <Field
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}
                                                    name="percent_sav"
                                                    component="input"
                                                    type="range"
                                                    max={100}
                                                    defaultValue={10}
                                                    placeholder="ZipCode"
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                        maxWidth: "6rem",
                                                    }}
                                                >
                                                    <Field
                                                        name="percent_sav"
                                                        component="input"
                                                        type="number"
                                                        defaultValue={10}
                                                    />
                                                    %
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Account Numbers:
                                            </span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="account_nums"
                                                    component="textarea"
                                                    defaultValue=""
                                                    placeholder="List of account numbers"
                                                />
                                            </div>
                                        </div>                                        

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Template:
                                            </span>
                                            <div>
                                                <Field
                                                    name="template"
                                                    component="select"
                                                    defaultValue={
                                                        selectedTemplate
                                                    }
                                                    className="select-light-border"
                                                    onChange={(e) => {
                                                        getTemplate();
                                                        setSelectedTemplate(
                                                            e.target.options[
                                                                e.target
                                                                    .selectedIndex
                                                            ].value
                                                        );
                                                    }}
                                                >
                                                    {/* <option value="">Nothing selected</option> */}
                                                    {emailTemplates.map(
                                                        (template) => (
                                                            <option
                                                                key={
                                                                    template.value
                                                                }
                                                                value={
                                                                    template.value
                                                                }
                                                            >
                                                                {template.label}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="form__form-group" style={{display: 'none'}}>
                                            <span className="form__form-group-label">
                                                Template:
                                            </span>
                                            <div className="form__form-group-field">
                                                <textarea name="email_body"
                                                    value={content}
                                                />
                                            </div>
                                        </div>  

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Schedule email date and time</span>
                                            <div className="form__form-group-field">
                                                <Field
                                                    name="schedule_send_time"
                                                    component={renderDateTimePickerField}
                                                    setMinDate={true}
                                                />
                                                <div className="form__form-group-icon">
                                                    <TimetableIcon />
                                                </div>
                                            </div>
                                        </div>    

                                        {selectedTemplate === "custom" && (
                                            <>
                                                <h3 style={{ marginTop: 56 }}>
                                                    Email Content
                                                </h3>
                                                <hr />
                                                <div className="form__form-group" style={{display: 'none'}}>
                                                    <span className="form__form-group-label">
                                                        Email Title:
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name="email_title"
                                                            component="input"
                                                            defaultValue="text"
                                                            placeholder="Title"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label">
                                                        Email Subject:
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name="email_subject"
                                                            component="input"
                                                            defaultValue="text"
                                                            placeholder="Subject"
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </>
                                        )}

                                        <div className="form__form-group">
                                            <span className="form__form-group-label">
                                                Email Body:
                                            </span>
                                            <div className="form__form-group-field">
                                                {/* <Field
                                                    name="email_body"
                                                    component="textarea"
                                                    defaultValue=""
                                                    placeholder="Body"
                                                /> */}

                                                <JoditEditor
                                                    key="editor-instance"
                                                    ref={editorRef}
                                                    value={content}
                                                    config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={newContent => {}}
                                                />
                                            </div>
                                        </div>
                                        
                                        <Button
                                            onClick={() => setType("test")}
                                            color="primary"
                                            type="submit"
                                            className="search-btn"
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <LoadingIcon className="send-email-loading" />
                                            ) : (
                                                <EmailSendIcon />
                                            )}
                                            Test Send Email
                                        </Button>
                                        <div className="send-email-btns text-right">
                                            <Button
                                                className="cancel__btn"
                                                onClick={reset}
                                            >
                                                <UndoIcon /> Reset
                                            </Button>
                                            <Button
                                                onClick={() => setType("live")}
                                                color="primary"
                                                type="submit"
                                                className="search-btn"
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <LoadingIcon className="send-email-loading" />
                                                ) : (
                                                    <EmailSendIcon />
                                                )}
                                                Send Email
                                            </Button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default reduxForm({
    form: "send_email",
    initialValues: {
        percent_sav: 0,
    },
})(SendEmail);