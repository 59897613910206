/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import FirebaseIcon from 'mdi-react/FirebaseIcon';
import withAuthFirebase from '@/shared/components/auth/withAuthFirebase';
// import { useAuth0 } from '@/shared/components/auth/withAuth0';
import Loading from '@/shared/components/Loading';
import LogInForm from '@/shared/components/loginForm/LogInForm';

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

// below lines should be inside const LogIn under  const LogIn = ({ changeIsOpenModalFireBase }) => {  
  //  const {
  //   loginWithRedirect, loading,
  // } = useAuth0();
  // if (loading) {
  //   return (<Loading loading={loading} />);
  // }



function LogIn({ changeIsOpenModalFireBase }){
  const [cookies, removeCookie] = useCookies(["name"]);
  const [registerDone, setRegisterDone] = useState(false);
  const location = useLocation();

  if(localStorage.getItem("loggedin_id") !== null && !_.isUndefined(cookies.token)){
    const userData = JSON.parse(localStorage.getItem('userData'));
    if(userData?.userlevel === 1){
      window.location = '/dashboard'; 
    }

    if(userData?.userlevel !== 1){
      window.location = '/dashboard'; 
    }
    return(<div></div>)
  
  }

  return(
    <>
  <div className="loginpage-header">
    <div className="loginlogo-wrapper">
      <Link className="topbar__logo" to="/" height="150px" />
      <Link to="/admin-login" className="btn btn-secondary mb-0">Switch to Admin</Link>
    </div>
  </div>
  <div className="account--not-photo">
    <div className="account__wrapper loginform-wrapper">
      <div className="account__card">
        { location.register_success &&
          <p><b>You have successfully registered. If you are signing up after May 15th, we will NOT be protesting on your behalf. If you were noticed late or have already filed your own protest, please email us at <a href="mailto:info@republicpropertytax.com">info@republicpropertytax.com</a>.</b></p>
        }
        <div className="login_header">
          {/* <Link className="topbar__logo" to="/" height="150px" />    */}
          <h1>Client Login</h1>
        </div>
        <LogInForm
          onSubmit
          form="log_in_form"
        />
      </div>
    </div>
  </div>
  <div className="loginpage-footer">
    <div className="loginfooter-wrapper">
      <p><span>2021 © Republic Property Tax</span>  <a href="www.republicpropertytax.com" target="_blank">www.republicpropertytax.com</a></p>
    </div>
  </div>
  </>
  )
}

LogIn.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(LogIn);
