/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import {  globalError } from "@/utils/message_herlper"

import CustomPagination from '@/components/CustomPagination'
import TableLoading from '@/components/TableLoading'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const CountyTable = (props) => {     
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);

    const [states, setStates] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [selectedState, setSelectedState] = useState('');
    const [selectedStateName, setSelectedStateName] = useState('Texas');
    const [dataTableData, setDataTableDate] = useState([])

    const columns = useMemo(
        () => [
            {
                name: "County Name",
                selector: "name",
                sortable: false,
            },
            {
                name: "District Name",
                selector: "district_name",
                sortable: false,
            },
            {
                name: "Address",
                selector: "address",
                sortable: false,
            },
            {
                name: "City",
                selector: "city",
                sortable: false,
                width: '140px',
            },
            {
                name: "State",
                selector: "state",
                sortable: false,
                width: '80px',
            },
            {
                name: "Zip",
                selector: "zipcode",
                sortable: false,
                width: '80px',
            },
            {
                name: "Agent Code	",
                selector: "agent_code",
                sortable: false,
            },
            {
                width: '70px',
                center: true,
                cell: (row) => (
                    <button className="btn btn-primary my-1" onClick={()=> handlEdit(row)}>Edit</button>
                ),
            },
        ],
    );

    const handlEdit = (row) => {
        history.push({ pathname: `/county-form/update/${row?.id}`})
   } 
    
    const fetchData = async (page, size = perPage, state = '' ) => {  
        ApiServices.countryList({'page' : page, 'per_page': size, 'state': state})
        .then((result) => {    
            setData(result?.data?.data)
            setTotalRows(result.data.total)
            setRowCount(result.data.data.length);

            const dataTableTemp = []
            const list = result?.data?.data || []

            for (let index = 0; index < list.length; index++) {
                dataTableTemp.push({
                    id: list[index].id,
                    name: list[index].name,
                    district_name: list[index].district_name,
                    address: list[index].address,
                    city: list[index].city,
                    state: list[index].state,
                    zipcode: list[index].zipcode,
                    agent_code: list[index].agent_code,
                })
            }

            setDataTableDate(dataTableTemp);

            setLoading(false)
        })
        .catch(function (error) {
            setLoading(false)
            globalError();
        });
    };


    const fetchStateData = async (page, size = perPage) => {  
        try {
            const result = await ApiServices.stateList({'page' : page, 'per_page': size});
            setStates(result?.data);
        } catch (error) {
            globalError();
        }
    };

    const handleDropdownToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleSelectedItem = (state) => {
        setSelectedState(state.code);
        setSelectedStateName(state.name)
        fetchData(1, perPage, state.code);
    }

    useEffect(() => {
        fetchData(1);
        fetchStateData(1)
    }, [props]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };


    

    return (
        <div className="activeProperties__wrapper">
            <div className="state-selector">
                <Dropdown isOpen={isOpen} toggle={handleDropdownToggle}>
                    <DropdownToggle caret>{ selectedStateName }</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Select a state</DropdownItem>
                        {states?.map((state) => (
                            <DropdownItem key={state.code} onClick={() => handleSelectedItem(state)}>
                                {state.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
            <DataTable
                columns={columns}
                data={dataTableData}
                progressPending={loading}
                progressComponent={<TableLoading/>}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
       </div>
    );
};

export default CountyTable;
