/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import ApiServices from "@/utils/ApiServices";
import Empty from "@/components/Empty";
import { TABLE_SETTINGS } from "@/utils/constant";
import { successMsg, globalError, formError } from "@/utils/message_herlper"

import CustomPagination from '@/components/CustomPagination'
import TableLoading from '@/components/TableLoading'

const StateTable = (props) => {     
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [perPage, setPerPage] = useState(TABLE_SETTINGS.PAGINATION_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = useMemo(
        () => [
            {
                name: "State Name",
                selector: "name",
                sortable: false,
            },
            {
                name: "Code",
                selector: "code",
                sortable: false,
            },
            {
                width: '70px',
                center: true,
                cell: (row) => (
                    <button className="btn btn-primary my-1" onClick={()=> handleDelete(row)}>Delete</button>
                ),
            },
        ],
    );

    const handleDelete = (row) => {
        ApiServices.stateDelete({ id: row?.id }).then((result) => {
            successMsg('STATE_DELETED') 
            fetchData(1);
        })
        .catch((error) => {
            globalError();
        })
   } 
    
    const fetchData = async (page, size = perPage, ) => {  
        ApiServices.stateList({'page' : page, 'per_page': size})
        .then((result) => {  
            setData(result?.data)
            setTotalRows(result.data.length)
            setRowCount(result.data.length);
            setLoading(false)
        })
        .catch(function (error) {
            setLoading(false)
            globalError();
        });
    };

    useEffect(() => {
        fetchData(1);
    }, [props]);
    

   
    const handlePageChange = (page) => {
        fetchData(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        fetchData(page, newPerPage);
        setPerPage(newPerPage);
    };


    const dataTable = []
    const list = data || []

    for (let index = 0; index < list.length; index++) {
        dataTable.push({
           id: list[index].id,
           name: list[index].name,
           code: list[index].code,
        })
        
    }

    return (
        <div className="activeProperties__wrapper">
            <DataTable
                columns={columns}
                data={dataTable}
                progressPending={loading}
                progressComponent={<TableLoading/>}
                pagination={false}
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                striped={TABLE_SETTINGS.STRIPED}
                highlightOnHover={true}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationPerPage={TABLE_SETTINGS.PAGINATION_PER_PAGE}
                paginationRowsPerPageOptions={TABLE_SETTINGS.ROW_PER_PAGE}
                noDataComponent={<Empty />}
            />
            <CustomPagination rowCount={rowCount} currentPage={currentPage} totalRows={totalRows} perPage={TABLE_SETTINGS.PAGINATION_PER_PAGE} handlePageChange={handlePageChange} />
       </div>
    );
};

export default StateTable;
