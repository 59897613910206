import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { TIMEZONE } from "@/utils/constant";
import { format } from "date-fns";

const convertToTimeZone = (date, timeZone) => {
  // Convert the date to the target time zone string
  const dateString = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // 24-hour format
  }).format(new Date(date));

  // Convert formatted string back to a Date object
  return new Date(dateString);
};

const DateTimePickerField = ({ onChange, setMinDate, name }) => {
  const [startDate, setStartDate] = useState(convertToTimeZone(new Date(), TIMEZONE));
  const [utcDate, setUtcDate] = useState("");

  const handleChange = (date) => {
    setStartDate(date);

    const convertedUtcDate = convertToTimeZone(date, TIMEZONE);
    setUtcDate(format(new Date(convertedUtcDate), "yyyy-MM-dd HH:mm:ss"));

    onChange(date);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ensure the time is midnight

  return (
    <div className="date-picker">
      <input type="hidden" name={name} value={utcDate || ''} readOnly />
      <DatePicker
        timeFormat="HH:mm"
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        dropDownMode="select"
        withPortal={isMobileOnly}
        minDate={setMinDate ? today : null}
        name={name}
      />
    </div>
  );
};

DateTimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  setMinDate: PropTypes.bool,
};

DateTimePickerField.defaultProps = {
  setMinDate: false, // Default to not setting minDate
  name: 'datetime'
};

const renderDateTimePickerField = ({ input, setMinDate, name }) => <DateTimePickerField {...input} setMinDate={setMinDate} name={name || input.name}/>;

renderDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  setMinDate: PropTypes.bool,
  name: PropTypes.string,
};

export default renderDateTimePickerField;
