/* eslint-disable */
import React, { useEffect } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Progress,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./userproperty.css";
import TitleBreadCrumbs from "@/components/TitleBreadCrumbs";
import HomeIcon from "@/images/homeIcon";
import MapMarker from "@/images/mapMarker";
import AccountOutlineIcon from "@/images/userMultipleIcon";
import UserIcon from "@/images/userIcon";
import PaperIcon from "@/images/paperIcon";
import { USERLEVELS } from "@/utils/constant";

const UserAndPropertyListing = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    return (
        <Container>
            <TitleBreadCrumbs title="Settings">
                <div className="page-breadcrumbs">
                    <Link to="/dashboard">Dashboard</Link>
                    <span className="current_breadcrumbs">Settings</span>
                </div>
            </TitleBreadCrumbs>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <Link to="/account-details" className="report-btn">
                                <div className="report-icon-wrapper">
                                    <UserIcon color="#009ADC" />
                                </div>
                                <div className="report-title-wrapper">
                                    <p>Account Details</p>
                                </div>
                            </Link>
                        </Col>
                        {userData?.userlevel === USERLEVELS.ADMIN && (
                            <Col md={6}>
                                <Link to="/stafflist" className="report-btn">
                                    <div className="report-icon-wrapper">
                                        <AccountOutlineIcon color="#009ADC" />
                                    </div>
                                    <div className="report-title-wrapper">
                                        <p>Staff members</p>
                                    </div>
                                </Link>
                            </Col>
                        )}
                        <Col md={6}>
                            <Link to="/county-list" className="report-btn">
                                <div className="report-icon-wrapper">
                                    <MapMarker color="#009ADC" />
                                </div>
                                <div className="report-title-wrapper">
                                    <p>Counties</p>
                                </div>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <Link to="/states" className="report-btn">
                                <div className="report-icon-wrapper">
                                    <MapMarker color="#009ADC" />
                                </div>
                                <div className="report-title-wrapper">
                                    <p>States</p>
                                </div>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <Link to="/property-type" className="report-btn">
                                <div className="report-icon-wrapper">
                                    <HomeIcon color="#009ADC" />
                                </div>
                                <div className="report-title-wrapper">
                                    <p>Property type</p>
                                </div>
                            </Link>
                        </Col>
                        {userData?.userlevel === USERLEVELS.ADMIN && (
                            <>
                                <Col md={6}>
                                    <Link
                                        to="/acknowledgment"
                                        className="report-btn mb-4"
                                    >
                                        <div className="report-icon-wrapper">
                                            <PaperIcon color="#009ADC" />
                                        </div>
                                        <div className="report-title-wrapper">
                                            <p>Acknowledgment</p>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md={6}>
                                    <Link
                                        to="/registration-settings"
                                        className="report-btn mb-0"
                                    >
                                        <div className="report-icon-wrapper">
                                            <PaperIcon color="#009ADC" />
                                        </div>
                                        <div className="report-title-wrapper">
                                            <p>Registration</p>
                                        </div>
                                    </Link>
                                </Col>
                            </>
                        )}
                        <Col md={6}>
                            <Link to="/exemption-type" className="report-btn">
                                <div className="report-icon-wrapper">
                                    <HomeIcon color="#009ADC" />
                                </div>
                                <div className="report-title-wrapper">
                                    <p>Exemption type</p>
                                </div>
                            </Link>
                        </Col>


                        
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default UserAndPropertyListing;
