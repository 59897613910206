/* eslint-disable */
import React, { useState } from 'react';
import { 
    Card, CardBody,  Container,
  } from 'reactstrap';
import { Link } from 'react-router-dom';
import ApiServices from "@/utils/ApiServices";
import StateTable from './components/StateTable';
import TitleBreadCrumbs from '@/components/TitleBreadCrumbs';
// import './state-style.css';

const States = () => {

    return (
        <>
        <Container>
            <TitleBreadCrumbs title="Acquired States"  link="/state-form/create" btn="Add State" >
                <div className="page-breadcrumbs">
                    <Link to="/">Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <span className="current_breadcrumbs">
                        States
                    </span>
                </div>
            </TitleBreadCrumbs>
            <Card className="card-controlled">
                <CardBody className="cardbody-controlled">
                    <div className="">
                        <StateTable />
                    </div>
                </CardBody>
            </Card>
        </Container>
        </>
    );
}

export default States;