/* eslint-disable */
import axios from "axios";
import { API, ENDPOINT, USERLEVELS } from "./constant";
import { withCookies, Cookies } from "react-cookie";

const cookies = new Cookies();
const token = localStorage.getItem("loggedin_token");

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            error?.response?.status === 401 &&
            !cookies.token &&
            localStorage.getItem("userData") !== null
        ) {
            //window.location = '/login?msg=session_expired'
            localStorage.removeItem("loggedin_id");
            localStorage.removeItem("loggedin_token");
            localStorage.removeItem("userData");
            const userData = JSON.parse(localStorage.getItem("userData"));
            window.location =
                userData.userlevel !== USERLEVELS.ADMIN !== USERLEVELS.STAFF_ROLE
                    ? "/login?msg=session_expired"
                    : "/admin-login?msg=session_expired";
        }
        throw error;
    }
);

// axios.interceptors.response.use(
//     response => response,
//    //errorResponseHandler
// );

function errorResponseHandler(error) {
    // check for errorHandle config
    // if( error?.config?.hasOwnProperty('errorHandle') && error?.config?.errorHandle === false ) {
    //     return Promise.reject(error);
    // }
    // if(error?.response?.status === 401 && !token){
    //     window.location = '/login?msg=session_expired'
    // }
}

export default class APIServices {
    static register(parameters) {
        return axios({
            method: "post",
            url: `${API}register`,
            data: parameters,
        });
    }

    static registerFile(parameters) {
        return axios({
            method: "post",
            url: `${API}register-file`,
            data: parameters,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    static login(parameters) {
        return axios({
            method: "post",
            url: `${API}login`,
            data: parameters,
        });
    }

    static confirmationCheck(parameters) {
        return axios({
            method: "post",
            url: `${API}confirmation-check`,
            data: parameters,
        });
    }

    static clientStore(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsListActiveInactive(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/client-list-active-inactive`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsList(parameters) {
        return axios({
            method: "post",
            url: `${API}client-list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsListNew(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/new`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsFilterList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/filter`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsCustomSearch(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/custom-search`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertiesFilterList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/filter`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static customPropertiesFilterList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/custom-search`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyList(parameters) {
        return axios({
            method: "post",
            url: `${API}property-list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyListNew(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/new`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static registerProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}register-property`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static PropertyTypes(parameters) {
        return axios({
            method: "get",
            url: `${API}property-type`,
            data: parameters,
        });
    }

    static clientsDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}client/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static county(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.COUNTY}`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsActiveProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/active-inactive-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientsActiveProperties2(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/active-inactive-properties2`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static saveNotes(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.NOTE}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static saveNotesProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.NOTE}/store-property`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static fetchNotes(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.NOTE}/fetch/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static fetchNotesProperty(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.NOTE}/fetch-property/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editClient(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/edit`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static viewEditPending(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/viewEditPending/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static viewPropPending(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/view-pending/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateOustanding(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/update-oustanding`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePassword(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/update-password`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateStatus(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/update-status`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyDetailsOnly(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/details-only/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateAofaready(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-aoa`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateAofareadyAddress(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-aoa-address`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateStatusProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-status`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/edit`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateHearingDateProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-hearing-date`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static getTemplate(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/get-template`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateTemplate(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-template/${parameters}`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editPropertiesModeration(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/editForModeration`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyBilling(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyBillingByProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/by-roperty`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePropertyBilling(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/edit`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyBillingDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static uploadDocument(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_DOCUENT}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    static propertyDocuments(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_DOCUENT}/list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyDescriptionFiles(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_DOCUENT}/description-files`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static deleteDocument(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY_DOCUENT}/delete/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateStatusDocument(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_DOCUENT}/update-status`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static clientActivities(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/activities/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyActivities(parameters) {
        return axios({
            method: "POST",
            url: `${API}${ENDPOINT.PROPERTY}/activities`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static allActivities(parameters) {
        return axios({
            method: "POST",
            url: `${API}${ENDPOINT.CLIENT}/activities-all/`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static activitiesFilter(parameters) {
        return axios({
            method: "POST",
            url: `${API}${ENDPOINT.CLIENT}/activities-filter/`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static viewAllPending(parameters) {
        return axios({
            method: "POST",
            url: `${API}${ENDPOINT.CLIENT}/viewAllPending/`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    //

    static editProfile(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/edit-profile`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static approveProfileChanges(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/approve-client-changes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static rejectProfileChanges(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/reject-client-changes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static approvePropertyChanges(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/approve-property-changes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static rejectPropertyChanges(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/reject-property-changes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static approveDeleteAccount(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/approve-delete-account`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static approveDeleteProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/approve-delete-property`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editProfileClient(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/edit-profile-client`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static requestEmail(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/requestEmail`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static createStaff(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/create-staff`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static staffList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/staff-list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static createCounty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.COUNTY}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static stateList(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.STATE}/states`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }
    static createState(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.STATE}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }
    static updateState(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.STATE}/update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }


    static stateDelete(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.STATE}/delete`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }



    static countryList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.COUNTY}/county-list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static countyDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.COUNTY}/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateCounty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.COUNTY}/update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static createPropertyType(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_TYPE}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyTypeList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_TYPE}/list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyTypeDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY_TYPE}/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePropertyType(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_TYPE}/update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyZipRerport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/property-byzip`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static notInvoiceRerport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/not-invoice`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static yearlyAccountingRerport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/yearly-accounting`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static yearlyAccountingExport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/yearly-accounting-export`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyZipRerportExport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/property-byzip-export`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static notInvoiceExport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/not-invoice-export`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static customExport(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.REPORT}/custom-export/${parameters.filterData}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static notInvoiceProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/not-invoice-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static resultsList(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/result-list`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static agentList(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/agent-list`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static agentListActive(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/agent-list-active`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static numberClient(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/number-client`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static numberClientCurrent(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/number-client-current`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static numberProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/number-property`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static numberPropertiesCurrent(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/number-properties-current`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static invoiceReport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/invoice-report`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static commissionReport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/commission-report`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static InvoiceProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/invoice-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static markReady(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/mark-ready/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static markReadyProperty(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/mark-ready/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static allActiveClientsExport(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.EXPORT}/all-active-clients`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static recent_view(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/recent-view`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static dashboardActive(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/dashboard-active`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static dashboardNewClient(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/dashboard-new-client`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static dashboardActiveProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/dashboard-active`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static dashboardNewProperty(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/dashboard-new-client`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static getallClient(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/all-client`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static customRerport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/custom`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static leadsRerport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/leads`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static getSettings(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/settings`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editSettings(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/settings-update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static accountSpecificReport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/account-spicific`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static accountSpecificProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/account-spicific-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static accountSpecificBilling(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/account-spicific-billing`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static resetPassword(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.MAIL}/reset-password`,
            data: parameters,
        });
    }

    static resetPasswordNew(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.AUTH}/reset-password-new`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static requestDeleteAccount(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.CLIENT}/delete-account`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static deleteProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/delete-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static deleteActivity(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/delele-activities`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static createExemption(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.EXEMPTION}/store`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static exemptionList(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.EXEMPTION}/list`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static exemptionDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.EXEMPTION}/details/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateExemption(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.EXEMPTION}/update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static ExemptionTypes(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.EXEMPTION}/all`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateHearingDate(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-hearing-date`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateAOASentDate(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/update-aoa-sent-date`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static removeAOADate(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/removeaoa`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePropertyBillingPaid(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/update-paid`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static propertyBillingExport(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.EXPORT}/property-billing-export`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static deleteClientNotes(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/delete-notes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static editClientNotes(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.CLIENT}/edit-notes`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static outstandingBalance(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.REPORT}/outstandng-balance`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePropertyBillingMarket(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/update-market-cut`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updatePropertyNoChange(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/update-no-change`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static sendArbitrationLetter(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY_BILLING}/send-arbitration-email`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static uploadMultiple = (file, onUploadProgress) => {
        let formData = new FormData();
        formData.append("file", file);
        return axios.post(`${API}upload-multiple-file`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer  ${token}`,
            },
            onUploadProgress,
            // onUploadProgress: progressEvent => {
            //     // let {loaded, total} = progressEvent;
            //     // console.log((loaded / total) * 100)
            //     onUploadProgressReturn(progressEvent,index);
            // },
        });
    };

    static getMultiUploads(parameters) {
        return axios({
            method: "post",
            url: `${API}get-upload-multiple-file`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static deleteMultiUploads(parameters) {
        return axios({
            method: "post",
            url: `${API}delete-upload-multiple-file`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static getMultiUploadsProperty(parameters) {
        return axios({
            method: "get",
            url: `${API}upload-multiple-file-property/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static getRegistrationSettings(parameters) {
        return axios({
            method: "get",
            url: `${API}settings/registration-settings`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static updateRegistrationSettings(parameters) {
        return axios({
            method: "post",
            url: `${API}settings/registration-settings-update`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    /**
     * 
     * @param {Object} parameters 
     *  @param {string} parameters.account_number
     * @returns 
     */
    static propertyAccountNumberExists(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.PROPERTY}/check-account-number-exist`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static sendBatchEmail(parameters) {
        return axios({
            method: "post",
            url: `${API}batch-email`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static CommissionInvoiceProperties(parameters) {
        return axios({
            method: "post",
            url: `${API}${ENDPOINT.REPORT}/commission-invoice-properties`,
            data: parameters,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }

    static arbitrationDetails(parameters) {
        return axios({
            method: "get",
            url: `${API}${ENDPOINT.PROPERTY}/arbitration/${parameters}`,
            headers: {
                Authorization: `Bearer  ${token}`,
            },
        });
    }
    
}
